import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IContactUsRequest, IContactUsResponse } from '../interfaces/contact-us';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private _HttpClient = inject(HttpClient);

  // gets all Social Links
  getSocialInfo(): Observable<IContactUsResponse[]> {
    return this._HttpClient.get<IContactUsResponse[]>(`${environment.endPointUrl}/v1/globals/social-links`);
  }

  sendContactUsMessage(formData: IContactUsRequest): Observable<any> {
    return this._HttpClient.post<any>(`${environment.endPointUrl}/v1/globals/contact-us`, formData);
  }

}
